import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import {
  Box,
  Button,
  Divider,
  Modal, 
  TextField, 
  Typography
} from '@mui/material';

import LoadingButton from '../../../../../../components/buttons/Button';

import { 
  CreateCCCountryBIZcoreCC, 
  UpdateCCCountryBIZcoreCC
} from '../../../../../../redux/actions/business/core/currencyCloudActions';

import global from "../../../../../../global.scss";

const Data = {
  Name: "",
  Code: ""
};

const CountriesModal = ({
  open, 
  setOpen, 
  title,
  update,
  handleGet
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(Data);
  const [mode, setMode] = useState(1);

  const handleClose = () => {
    setOpen(false);
    setFormData(Data);
    setMode(1);
  }

  useEffect(() => {
    try {
      if(open){
        if(update.defaultMode === 1){
          // mode = 1 - create country
          setMode(1);
        }else if(update.defaultMode === 2){
          // mode = 2 - edit country
          setMode(2);
          setFormData({
            ...formData,
            Name: update.name || "",
            Code: update.code || "",
          });
        }else{
          toast.error("Invalid modal");
          handleClose();
        }
      }
    } catch (err) {
      console.log(err);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  // --------------- handle change ----------------
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    if(mode === 1){
      // create country
      let obj = {
        name: formData.Name || "",
        code: formData.Code || "",
      };
      dispatch(CreateCCCountryBIZcoreCC(obj, setLoading)).then(() => {
        handleGet(setLoading).then(() => {
          handleClose();
        })
      });
    }else if(mode === 2){
      // update country
      let obj = {
        name: formData.Name || "",
        code: formData.Code || "",
      };
      dispatch(UpdateCCCountryBIZcoreCC(update.id, obj, setLoading)).then(() => {
        handleGet(setLoading).then(() => {
          handleClose();
        })
      });
    }else{
      toast.error("Invalid modal");
      handleClose();
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className="flexCenterCenterRow"
        width={"100vw"} height={"100vh"}>
        <Box maxWidth={"sm"} className="modal">
          <Box width={"100%"}
            sx={{ padding: "15px"}}>
            <Typography variant='h6' 
              sx={{ color: global["primary"] }} mb={"20px"}>
              {title}
            </Typography>
            <Box 
              className="flexCenterSBRow"
              gap={"15px"}
              mb={"15px"}>
              <TextField 
                label="Name"
                fullWidth
                name="Name"
                value={formData.Name}
                onChange={handleChange}
                inputProps={{ maxLength: 64 }}
                />
              <TextField 
                label="Code"
                fullWidth
                name="Code"
                value={formData.Code}
                onChange={handleChange}
                inputProps={{ maxLength: 2 }}
                />
            </Box>
          </Box>
          <Divider
           sx={{ backgroundColor: "rgba(44, 45, 47, 1)" }}/>
          <Box className="flexCenterFERow" sx={{ gap: "20px", margin: "15px"}}>
            <Button variant='text'
              onClick={handleClose}>
              Cancel
            </Button>
            <LoadingButton 
              variant='contained'
              loading={loading}
              disabled={!(formData.Name !== "" && formData.Code !== "")}
              onClick={handleSubmit}>
              Submit
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default CountriesModal;