import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import {
  Box,
  Button,
  Divider,
  MenuItem,
  Modal, 
  TextField, 
  Typography
} from '@mui/material';

import LoadingButton from '../../../../../../components/buttons/Button';

import { 
	CreatePaymentServiceBIZcoreCC, 
	GetPaymentCountryCurrencyXrefBIZcoreCC, 
  UpdatePaymentServiceBIZcoreCC
} from '../../../../../../redux/actions/business/core/currencyCloudActions';

import global from "../../../../../../global.scss";

const paymentTypeList = [
  { label: "Priority", value: "PRIORITY" },
  { label: "Regular", value: "REGULAR" },
]

const Data = {
  CountryCurrencyId: "",
  PaymentType: "",
	CutOffTime: "",
  MinLimit: "",
  MaxLimit: "",
  DeliveryTimeFrame: ""
};

const PaymentServicesModal = ({
  open, 
  setOpen, 
  title,
  update,
  handleGet
}) => {
  const dispatch = useDispatch();
	const state = useSelector(state => state["business.core.cc"]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(Data);
  const [mode, setMode] = useState(1);

  const [paymentCountryCurrencyXrefList, setPaymentCountryCurrencyXrefList] = useState([]);

  const handleClose = () => {
    setOpen(false);
    setFormData(Data);
    setMode(1);
  };

  useEffect(() => {
    try {
			if(open){
				dispatch(GetPaymentCountryCurrencyXrefBIZcoreCC(1, 1000, "", setLoading));
				
        if(update.defaultMode === 1){
          // mode = 1 - create payment service
          setMode(1);
        }else if(update.defaultMode === 2){
          // mode = 2 - edit payment service
          setMode(2);
          setFormData({
            ...formData,
            CountryCurrencyId: update.ref_pmt_country_currency_id || "",
            PaymentType: update.payment_type || "",
            CutOffTime: update.cutoff_time || "",
            MinLimit: update.min_limit || "",
            MaxLimit: update.max_limit || "",
            DeliveryTimeFrame: update.delivery_time_frame || ""
          });
        }else{
          toast.error("Invalid modal");
          handleClose();
        }
      }
    } catch (err) {
      console.log(err);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

	useEffect(() => {
		try {
			formatPaymentCountryCurrencyXrefList(state.paymentCountryCurrencyXrefList.records || []);
		} catch (err) {}
	}, [state]);

  const formatPaymentCountryCurrencyXrefList = (list) => {
    setPaymentCountryCurrencyXrefList(list);
  };

  // --------------- handle change ----------------
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    if(mode === 1){
      // create payment service
      let obj = {
        ref_pmt_country_currency_id: formData.CountryCurrencyId || "",
        payment_type: formData.PaymentType || "",
        cutoff_time: new Date(),
        min_limit: formData.MinLimit || "",
        max_limit: formData.MaxLimit || "",
        delivery_time_frame: formData.DeliveryTimeFrame || ""
        // ['ref_pmt_country_currency_id', 'payment_type', 'cutoff_time', 'min_limit', 'max_limit', 'delivery_time_frame']
      };
      dispatch(CreatePaymentServiceBIZcoreCC(obj, setLoading)).then(() => {
        handleGet(setLoading).then(() => {
          handleClose();
        })
      });
    }else if(mode === 2){
      // update payment service
      let obj = {
			  ref_pmt_country_currency_id: formData.CountryCurrencyId || "",
        payment_type: formData.PaymentType || "",
        cutoff_time: formData.CutOffTime || "",
        min_limit: formData.MinLimit || "",
        max_limit: formData.MaxLimit || "",
        delivery_time_frame: formData.DeliveryTimeFrame || ""
      };
      dispatch(UpdatePaymentServiceBIZcoreCC(update.id, obj, setLoading)).then(() => {
        handleGet(setLoading).then(() => {
          handleClose();
        })
      });
    }else{
      toast.error("Invalid modal");
      handleClose();
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className="flexCenterCenterRow"
        width={"100vw"} height={"100vh"}>
        <Box maxWidth={"sm"} className="modal">
          <Box width={"100%"}
            sx={{ padding: "15px"}}>
            <Typography variant='h6' 
              sx={{ color: global["primary"] }} mb={"20px"}>
              {title}
            </Typography>
						<Box 
              className="flexCenterSBRow"
              gap={"15px"}
              mb={"15px"}>
							<TextField 
								select
								label="CountryCurrencyId"
								fullWidth
								name="CountryCurrencyId"
								value={formData.CountryCurrencyId}
								onChange={handleChange}
								>
								{
									paymentCountryCurrencyXrefList.map((item, index) => (
										<MenuItem
											key={index}
											value={item.id}>
											{item.name} - {item.code}
										</MenuItem>
									))
								}
							</TextField>
            </Box>
            <Box 
              className="flexCenterSBRow"
              gap={"15px"}
              mb={"15px"}>
							<TextField 
								select
								label="PaymentType"
								fullWidth
								name="PaymentType"
								value={formData.PaymentType}
								onChange={handleChange}
								>
								{
									paymentTypeList.map((item, index) => (
										<MenuItem
											key={index}
											value={item.value}>
											{item.label}
										</MenuItem>
									))
								}
							</TextField>
            </Box>
            <Box 
              className="flexCenterSBRow"
              gap={"15px"}
              mb={"15px"}>
              <TextField 
                label="MinLimit"
                type='number'
                fullWidth
                name="MinLimit"
                value={formData.MinLimit}
                onChange={handleChange}
                />
              <TextField 
                label="MaxLimit"
                type='number'
                fullWidth
                name="MaxLimit"
                value={formData.MaxLimit}
                onChange={handleChange}
                />
            </Box>
            <Box 
              className="flexCenterSBRow"
              gap={"15px"}
              mb={"15px"}>
              <TextField 
                label="DeliveryTimeFrame"
                type='number'
                fullWidth
                name="DeliveryTimeFrame"
                value={formData.DeliveryTimeFrame}
                onChange={handleChange}
                />
            </Box>
          </Box>
          <Divider
           sx={{ backgroundColor: "rgba(44, 45, 47, 1)" }}/>
          <Box className="flexCenterFERow" sx={{ gap: "20px", margin: "15px" }}>
            <Button variant='text'
              onClick={handleClose}>
              Cancel
            </Button>
            <LoadingButton 
              variant='contained'
              loading={loading}
              disabled={!(formData.CountryCurrencyId !== "" && formData.PaymentType !== "" && 
                formData.MinLimit !== "" && formData.MaxLimit !== "" && formData.DeliveryTimeFrame !== "")}
              onClick={handleSubmit}>
              Submit
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default PaymentServicesModal;