import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { 
  Box,
	Container, 
	Typography 
} from '@mui/material';

import PublicRoutes from './publicRoutes';
import ProtectedRoutes from './protectedRoutes';
import Navigation from "../components/navigation/Navigation";
import ErrorHandler from '../components/errorHandler/ErrorHandler';
import packageJson from "../../package.json"; 

//routes
import AuthPage from '../views/authPage/AuthPage';
import HomePage from '../views/homePage/HomePage';

// MODULE - WatchTower
import HomePageWatchTower from '../views/WatchTower/homePage/HomePage';
import ContactRequestsPage from '../views/WatchTower/contactRequestsPage/ContactRequestsPage';
import AppVersionsPageWatchTower from '../views/WatchTower/appVersionsPage/AppVersionsPage';

// SUB MODULE - WatchTower/Environment
import HomePageWatchTowerEnv from '../views/WatchTower/environment/homePage/HomePage';
import ConsumerPageWatchTowerEnv from '../views/WatchTower/environment/consumerPage/ConsumerPage';
import BusinessPageWatchTowerEnv from '../views/WatchTower/environment/businessPage/BusinessPage';
import SDKPageWatchTowerEnv from '../views/WatchTower/environment/sdkPage/SDKPage';
import SupportPageWatchTowerEnv from '../views/WatchTower/environment/supportPage/SupportPage';

// MODULE - Consumer
import HomePageConsumer from '../views/Consumer/homePage/HomePage';
import UsersPage from '../views/Consumer/usersPage/UsersPage';
import InternalUsersPage from '../views/Consumer/internalUsersPage/InternalUsersPage';

// SUB MODULE - Consumer/Extension
import HomePageConsumerExt from  '../views/Consumer/extension/homePage/HomePage';
import CardRulesPage from '../views/Consumer/extension/cardRulesPage/CardRulesPage';
import PriceRulesPage from '../views/Consumer/extension/priceRulesPage/PriceRulesPage';

// SUB MODULE - Consumer/Payments
// import CurrenciesPageConsumerPmt from '../views/Consumer/payments/currenciesPage/CurrenciesPage';
// import CurrenciesPageConsumerPmt from '../views/Consumer/payments/currenciesPage/CurrenciesPage';
// import UsersPageConsumerPmt from '../views/Consumer/payments/usersPage/UsersPage';

// SUB MODULE - Consumer/VirtualCards
import CurrenciesPageConsumerVcn from '../views/Consumer/virtualCards/currenciesPage/CurrenciesPage';


// MODULE - Business
import HomePageBusiness from '../views/Business/homePage/HomePage';

// SUB MODULE - Business/Extension
import HomePageBusinessExt from '../views/Business/extension/homePage/HomePage';
import UploadedFilesPage from '../views/Business/uploadedFilesPage/UploadedFilesPage';
import CurrenciesPageBusiness from '../views/Business/currenciesPage/CurrenciesPage';

// SUB MODULE - Business/Notifications
import EmailTemplatesPage from '../views/Business/notifications/emailTemplatesPage/EmailTemplatesPage';
import EmailActivitiesPage from '../views/Business/notifications/emailActivitiesPage/EmailActivitiesPage';
import SmsTemplatesPage from '../views/Business/notifications/smsTemplatesPage/SmsTemplatesPage';
import SmsActivitiesPage from '../views/Business/notifications/smsActivitiesPage/SmsActivitiesPage';
import OtpRecordsPage from '../views/Business/notifications/otpRecordsPage/OtpRecordsPage';

// SUB MODULE - Business/Subscriptions
import HomePageBusinessSubs from '../views/Business/subscriptions/homePage/HomePage';
import ProductsPageBusinessSubs from '../views/Business/subscriptions/productsPage/ProductsPage';
import DeliveryMethodsPageBusinessSubs from '../views/Business/subscriptions/deliveryMethodsPage/DeliveryMethodsPage';
import IntegrationsPageBusinessSubs from '../views/Business/subscriptions/integrationsPage/IntegrationsPage';
import SubscriptionPlansPageBusinessSubs from '../views/Business/subscriptions/subscriptionPlansPage/SubscriptionPlansPage';
import ServicesPageBusinessSubs from '../views/Business/subscriptions/servicesPage/ServicesPage';
import PlanAddonsPageBusinessSubs from '../views/Business/subscriptions/planAddonsPage/PlanAddonsPage';
import CompanyPlanAddonsPageBusinessSubs from '../views/Business/subscriptions/companyPlanAddonsPage/CompanyPlanAddonsPage';
import DisallowedServicesPageBusinessSubs from '../views/Business/subscriptions/disallowedServicesPage/DisallowedServicesPage';

// SUB MODULE - Business/Identity
import HomePageBusinessIden from '../views/Business/identity/homePage/HomePage';
import CompaniesPageBusinessIden from '../views/Business/identity/companiesPage/CompaniesPage';
import BranchesPage from '../views/Business/identity/branchesPage/BranchesPage';
import UsersPageBusinessIden from '../views/Business/identity/usersPage/UsersPage';
import CompanyEmployeesPage from '../views/Business/identity/companyEmployeesPage/CompanyEmployeesPage';
import TenantsPage from '../views/Business/identity/tenantsPage/TenantsPage';

// SUB MODULE - Business/Core
import HomePageBusinessCore from '../views/Business/core/homePage/HomePage';
import CurrenciesPageBusinessCore from '../views/Business/core/global/currenciesPage/CurrenciesPage';
import IndustriesPageBusinessCore from '../views/Business/core/global/industriesPage/IndustriesPage';
import AccountTypesPageBusinessCore from '../views/Business/core/global/accountTypesPage/AccountTypesPage';
import ChartOfAccountsPageBusinessCore from '../views/Business/core/global/chartOfAccountsPage/ChartOfAccountsPage';
import CCCountriesPageBusinessCoreCC from '../views/Business/core/global/currencyCloud/countriesPage/CountriesPage';
import CCCurrenciesPageBusinessCoreCC from '../views/Business/core/global/currencyCloud/currenciesPage/CurrenciesPage';
import PaymentCountriesPageBusinessCoreCC from '../views/Business/core/global/currencyCloud/paymentCountriesPage/PaymentCountriesPage';
import PaymentCurrenciesPageBusinessCoreCC from '../views/Business/core/global/currencyCloud/paymentCurrenciesPage/PaymentCurrenciesPage';
import PaymentPurposesPageBusinessCoreCC from '../views/Business/core/global/currencyCloud/paymentPurposesPage/PaymentPurposesPage';
import PaymentCountryCurrencyXrefPageBusinessCoreCC from '../views/Business/core/global/currencyCloud/paymentCountryCurrencyXrefPage/PaymentCountryCurrencyXrefPage';
import PaymentServicesPageBusinessCoreCC from '../views/Business/core/global/currencyCloud/paymentServicesPage/PaymentServicesPage';
import CollectionCountriesPageBusinessCoreCC from '../views/Business/core/global/currencyCloud/collectionCountriesPage/CollectionCountriesPage';
import CollectionCurrenciesPageBusinessCoreCC from '../views/Business/core/global/currencyCloud/collectionCurrenciesPage/CollectionCurrenciesPage';
import CollectionCountryCurrencyXrefPageBusinessCoreCC from '../views/Business/core/global/currencyCloud/collectionCountryCurrencyXrefPage/CollectionCountryCurrencyXrefPage';
import CollectionServicesPageBusinessCoreCC from '../views/Business/core/global/currencyCloud/collectionServicesPage/CollectionServicesPage';

import TenantsPageBusinessCore from '../views/Business/core/tenant/tenantsPage/TenantsPage';
import BankAccountTypesPageBusinessCore from '../views/Business/core/tenant/bankAccountTypesPage/BankAccountTypesPage';
import BanksPageBusinessCore from '../views/Business/core/tenant/banksPage/BanksPage';
import HolidaysPageBusinessCore from '../views/Business/core/tenant/holidaysPage/HolidaysPage';
import CategoriesPageBusinessCore from '../views/Business/core/tenant/categoriesPage/CategoriesPage';
import CostObjectsPageBusinessCore from '../views/Business/core/tenant/costObjectsPage/CostObjectsPage';
import TenantBankAccountsPageBusinessCore from '../views/Business/core/tenant/tenantBankAccountsPage/TenantBankAccountsPage';
import DeliveryMethodsPageBusinessCore from '../views/Business/core/tenant/deliveryMethodsPage/DeliveryMethodsPage';
import ServiceFeesPageBusinessCore from '../views/Business/core/tenant/serviceFeesPage/ServiceFeesPage';
import PaymentFrequenciesPageBusinessCore from '../views/Business/core/tenant/paymentFrequenciesPage/PaymentFrequenciesPage';
import PaymentMethodsPageBusinessCore from '../views/Business/core/tenant/paymentMethodsPage/PaymentMethodsPage';
import CompaniesPageBusinessCore from '../views/Business/core/company/companiesPage/CompaniesPage';
import EmployeesPageBusinessCore from '../views/Business/core/company/employeesPage/EmployeesPage';
import CompanyBankAccountsPageBusinessCore from '../views/Business/core/company/companyBankAccountsPage/CompanyBankAccountsPage';
import DiscountsPageBusinessCore from '../views/Business/core/company/discountsPage/DiscountsPage';
import BillingPaysPageBusinessCore from '../views/Business/core/company/billingPaysPage/BillingPaysPage';
import BillingReceivesPageBusinessCore from '../views/Business/core/company/billingReceivesPage/BillingReceivesPage';
import BillingExpensesPageBusinessCore from '../views/Business/core/company/billingExpensesPage/BillingExpensesPage';

import DomesticTransfersPageBusinessCoreRPT from '../views/Business/core/reports/domesticTransfersPage/DomesticTransfersPage';
import InternationalTransfersPageBusinessCoreRPT from '../views/Business/core/reports/internationalTransfersPage/InternationalTransfersPage';

// SUB MODULE - Business/Fusion
import HomePageBusinessFusion from '../views/Business/fusion/homePage/HomePage';
import ActivitiesPageBusinessFusion from '../views/Business/fusion/activitiesPage/ActivitiesPage';
import HomePageBusinessFusionOrum from '../views/Business/fusion/orum/homePage/HomePage';
import BusinessesPageBusinessFusionOrum from '../views/Business/fusion/orum/businessesPage/BusinessesPage';
import PersonsPageBusinessFusionOrum from '../views/Business/fusion/orum/personsPage/PersonsPage';
import TransferPageBusinessFusionOrum from '../views/Business/fusion/orum/transferPage/TransferPage';
import VerifyPageBusinessFusionOrum from '../views/Business/fusion/orum/verifyPage/VerifyPage';
import WebhooksPageBusinessFusionOrum from '../views/Business/fusion/orum/webhooksPage/WebhooksPage';
// import HomePageBusinessFusionCC from '../views/Business/fusion/currencyCloud/homePage/HomePage';
import AccountsPageBusinessFusionCC from '../views/Business/fusion/currencyCloud/accountsPage/AccountsPage';
import BeneficiariesPageBusinessFusionCC from '../views/Business/fusion/currencyCloud/beneficiariesPage/BeneficiariesPage';
import ConversionsPageBusinessFusionCC from '../views/Business/fusion/currencyCloud/conversionsPage/ConversionsPage';
import RatesPageBusinessFusionCC from '../views/Business/fusion/currencyCloud/ratesPage/RatesPage';
import PaymentsPageBusinessFusionCC from '../views/Business/fusion/currencyCloud/paymentsPage/PaymentsPage';
import TransferPageBusinessFusionCC from '../views/Business/fusion/currencyCloud/transfersPage/TransferPage';
import HomePageBusinessFusionALT from '../views/Business/fusion/altcard/homePage/HomePage';
import PartiesPageBusinessFusionALT from '../views/Business/fusion/altcard/partiesPage/PartiesPage';
import PartiesBankAccountsPageBusinessFusionALT from '../views/Business/fusion/altcard/partiesBankAccountsPage/PartiesBankAccountsPage';

// SUB MODULE - Business/Webhook
import HomePageBusinessWebhook from '../views/Business/webhook/homePage/HomePage';
import ActivitiesPageBusinessWebhook from '../views/Business/webhook/activitiesPage/ActivitiesPage';
import WebhookLogsPageBusinessWebhook from '../views/Business/webhook/webhookLogsPage/WebhookLogsPage';


// MODULE - SDK
import HomePageSDK from '../views/SDK/homePage/HomePage';

// MODULE - Support
import HomePageSupport from '../views/Support/homePage/HomePage';
import IssuesPage from '../views/Support/issuesPage/IssuesPage';

// SUB MODULE - Consumer/Config
import IssueCategoriesPage from '../views/Support/config/issueCategoriesPage/IssueCategoriesPage';
import IssueStagesPage from '../views/Support/config/issueStagesPage/IssueStagesPage';

const MainRouter = () => {

  return (
    <Router>
      <Routes>
        <Route element={<PublicRoutes />}>
          <Route path='/' element={<AuthPage />} />
        </Route>
        <Route element={<ProtectedRoutes />}>
          <Route path='/' element={<Navigation />}>
            <Route path="home" element={<HomePage />} />
            {/* WatchTower */}  
            <Route path="watch" >
              <Route path='' element={<HomePageWatchTower />} />
              <Route path="contact-requests" element={<ContactRequestsPage />} />
              <Route path="app-versions" element={<AppVersionsPageWatchTower />} />

              {/* WatchTower/Environment */}
              <Route path='environment' element={<HomePageWatchTowerEnv />} />
              <Route path="environment/consumer" element={<ConsumerPageWatchTowerEnv />} />
              <Route path="environment/business" element={<BusinessPageWatchTowerEnv />} />
              <Route path="environment/sdk" element={<SDKPageWatchTowerEnv />} />
              <Route path="environment/support" element={<SupportPageWatchTowerEnv />} />
            </Route>

            {/* Consumer */}
            <Route path="consumer" >
              <Route path='' element={<HomePageConsumer />} />
              <Route path='users' element={<UsersPage />} />
              <Route path='internal-users' element={<InternalUsersPage />} />

              {/* Consumer/Extension */}
              <Route path='extension' element={<HomePageConsumerExt />} />
              <Route path='extension/card' element={<CardRulesPage />} />
              <Route path='extension/price' element={<PriceRulesPage />} />

              {/* Consumer/Payments */}
              {/* <Route path='payments/currencies' element={<CurrenciesPageConsumerPmt />} />
              <Route path='payments/users' element={<UsersPageConsumerPmt />} /> */}
            
              {/* Consumer/VirtualCards */}
              <Route path='virtual-cards/currencies' element={<CurrenciesPageConsumerVcn />} />

            </Route>

            {/* Business */}
            <Route path="business" >
              <Route path='' element={<HomePageBusiness />} />
              <Route path='uploaded-files' element={<UploadedFilesPage />} />
              <Route path='currencies' element={<CurrenciesPageBusiness />} />

              {/* Business/Extension */}
              <Route path='extension' element={<HomePageBusinessExt />} />

              {/* Business/Notifications */}
              <Route path='notifications/email-templates' element={<EmailTemplatesPage />} />
              <Route path='notifications/email-activities' element={<EmailActivitiesPage />} />
              <Route path='notifications/sms-templates' element={<SmsTemplatesPage />} />
              <Route path='notifications/sms-activities' element={<SmsActivitiesPage />} />
              <Route path='notifications/otp-records' element={<OtpRecordsPage />} />

              {/* Business/Subscriptions */}
              <Route path='subscriptions' element={<HomePageBusinessSubs />} />
              <Route path='subscriptions/products' element={<ProductsPageBusinessSubs />} />
              <Route path='subscriptions/delivery-methods' element={<DeliveryMethodsPageBusinessSubs />} />
              <Route path='subscriptions/integrations' element={<IntegrationsPageBusinessSubs />} />
              <Route path='subscriptions/subscription-plans' element={<SubscriptionPlansPageBusinessSubs />} />
              <Route path='subscriptions/services' element={<ServicesPageBusinessSubs />} />
              <Route path='subscriptions/plan-addons' element={<PlanAddonsPageBusinessSubs />} />
              <Route path='subscriptions/company-plan-addons' element={<CompanyPlanAddonsPageBusinessSubs />} />
              <Route path='subscriptions/disallowed-services' element={<DisallowedServicesPageBusinessSubs />} />

              {/* Business/Identity */}
              <Route path='identity' element={<HomePageBusinessIden />} />
              <Route path='identity/companies' element={<CompaniesPageBusinessIden />} />
              <Route path='identity/branches' element={<BranchesPage />} />
              <Route path='identity/users' element={<UsersPageBusinessIden />} />
              <Route path='identity/company-employees' element={<CompanyEmployeesPage />} />
              <Route path='identity/tenants' element={<TenantsPage />} />

              {/* Business/Core */}
              <Route path='core' element={<HomePageBusinessCore />} />
              <Route path='core/global/currencies' element={<CurrenciesPageBusinessCore />} />
              <Route path='core/global/industries' element={<IndustriesPageBusinessCore />} />
              <Route path='core/global/account-types' element={<AccountTypesPageBusinessCore />} />
              <Route path='core/global/chart-of-accounts' element={<ChartOfAccountsPageBusinessCore />} />
              <Route path='core/global/currency-cloud/countries' element={<CCCountriesPageBusinessCoreCC />} />
              <Route path='core/global/currency-cloud/currencies' element={<CCCurrenciesPageBusinessCoreCC />} />
              <Route path='core/global/currency-cloud/payment-countries' element={<PaymentCountriesPageBusinessCoreCC />} />
              <Route path='core/global/currency-cloud/payment-currencies' element={<PaymentCurrenciesPageBusinessCoreCC />} />
              <Route path='core/global/currency-cloud/payment-purposes' element={<PaymentPurposesPageBusinessCoreCC />} />
              <Route path='core/global/currency-cloud/payment-country-currency-xref' element={<PaymentCountryCurrencyXrefPageBusinessCoreCC />} />
              <Route path='core/global/currency-cloud/payment-services' element={<PaymentServicesPageBusinessCoreCC />} />
              <Route path='core/global/currency-cloud/collection-countries' element={<CollectionCountriesPageBusinessCoreCC />} />
              <Route path='core/global/currency-cloud/collection-currencies' element={<CollectionCurrenciesPageBusinessCoreCC />} />
              <Route path='core/global/currency-cloud/collection-country-currency-xref' element={<CollectionCountryCurrencyXrefPageBusinessCoreCC />} />
              <Route path='core/global/currency-cloud/collection-services' element={<CollectionServicesPageBusinessCoreCC />} />
              <Route path='core/tenants' element={<TenantsPageBusinessCore />} />
              <Route path='core/tenants/bank-account-types' element={<BankAccountTypesPageBusinessCore />} />
              <Route path='core/tenants/banks' element={<BanksPageBusinessCore />} />
              <Route path='core/tenants/holidays' element={<HolidaysPageBusinessCore />} />
              <Route path='core/tenants/categories' element={<CategoriesPageBusinessCore />} />
              <Route path='core/tenants/cost-objects' element={<CostObjectsPageBusinessCore />} />
              <Route path='core/tenants/tenant-bank-accounts' element={<TenantBankAccountsPageBusinessCore />} />
              <Route path='core/tenants/delivery-methods' element={<DeliveryMethodsPageBusinessCore />} />
              <Route path='core/tenants/service-fees' element={<ServiceFeesPageBusinessCore />} />
              <Route path='core/tenants/payment-frequencies' element={<PaymentFrequenciesPageBusinessCore />} />
              <Route path='core/tenants/payment-methods' element={<PaymentMethodsPageBusinessCore />} />
              <Route path='core/companies' element={<CompaniesPageBusinessCore />} />
              <Route path='core/companies/employees' element={<EmployeesPageBusinessCore />} />
              <Route path='core/companies/bank-accounts' element={<CompanyBankAccountsPageBusinessCore />} />
              <Route path='core/companies/discounts' element={<DiscountsPageBusinessCore />} />
              <Route path='core/companies/billing-pay' element={<BillingPaysPageBusinessCore />} />
              <Route path='core/companies/billing-receive' element={<BillingReceivesPageBusinessCore />} />
              <Route path='core/companies/billing-expense' element={<BillingExpensesPageBusinessCore />} />
              <Route path='core/reports/domestic-transfers' element={<DomesticTransfersPageBusinessCoreRPT />} />
              <Route path='core/reports/international-transfers' element={<InternationalTransfersPageBusinessCoreRPT />} />

              {/* Business/Fusion */}
              <Route path='fusion' element={<HomePageBusinessFusion />} />
              <Route path='fusion/activities' element={<ActivitiesPageBusinessFusion />} />
              <Route path='fusion/orum' element={<HomePageBusinessFusionOrum />} />
              <Route path='fusion/orum/businesses' element={<BusinessesPageBusinessFusionOrum />} />
              <Route path='fusion/orum/persons' element={<PersonsPageBusinessFusionOrum />} />
              <Route path='fusion/orum/transfers' element={<TransferPageBusinessFusionOrum />} />
              <Route path='fusion/orum/verify' element={<VerifyPageBusinessFusionOrum />} />
              <Route path='fusion/orum/webhooks' element={<WebhooksPageBusinessFusionOrum />} />
              <Route path='fusion/currency-cloud' element={<AccountsPageBusinessFusionCC />} />
              {/* <Route path='fusion/currency-cloud/accounts' element={<AccountsPageBusinessFusionCC />} /> */}
              <Route path='fusion/currency-cloud/beneficiaries' element={<BeneficiariesPageBusinessFusionCC />} />
              <Route path='fusion/currency-cloud/conversions' element={<ConversionsPageBusinessFusionCC />} />
              <Route path='fusion/currency-cloud/rates' element={<RatesPageBusinessFusionCC />} />
              <Route path='fusion/currency-cloud/payments' element={<PaymentsPageBusinessFusionCC />} />
              <Route path='fusion/currency-cloud/transfers' element={<TransferPageBusinessFusionCC />} />
              <Route path='fusion/altcard' element={<HomePageBusinessFusionALT />} />
              <Route path='fusion/altcard/parties' element={<PartiesPageBusinessFusionALT />} />
              <Route path='fusion/altcard/parties-bank-accounts' element={<PartiesBankAccountsPageBusinessFusionALT />} />
              
              {/* Business/Webhook */}
              <Route path='webhook' element={<HomePageBusinessWebhook />} />
              <Route path='webhook/activities' element={<ActivitiesPageBusinessWebhook />} />
              <Route path='webhook/webhook-logs' element={<WebhookLogsPageBusinessWebhook />} />

            </Route>

            {/* SDK */}
            <Route path="sdk" >
              <Route path='' element={<HomePageSDK />} />
            </Route>

            {/* Support */}
            <Route path="support" >
              <Route path='' element={<HomePageSupport />} />
              <Route path='issues' element={<IssuesPage />} />

              {/* Support/Config */}
              <Route path='config/categories' element={<IssueCategoriesPage />} />
              <Route path='config/stages' element={<IssueStagesPage />} />
            </Route>

          </Route>
        </Route>
        <Route path="/version" element={<AppVersion />} />
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
      <ErrorHandler />
    </Router>
  )
}

export default MainRouter;

// 404 page
const NotFoundPage = () => {
  return(
		<Container maxWidth="lg">
      <Box sx={{ height: "calc(100vh)", backgroundColor: "rgb(21, 22, 23)" }} className="flexCenterCenterRow">
			  <Typography variant='h3'>404 - Page not found</Typography>
      </Box>
		</Container>
  )
};

const AppVersion = () => {
  return(
    <Box
      sx={{ height: "100vh" }}>
      <Typography
        color={"black"}>
        App version: {packageJson.version}
      </Typography>
    </Box>
  )
};