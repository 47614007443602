//ERRORS
export const SET_ERRORS = "SET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
//CRASHES
export const SET_CRASHES = "SET_CRASHES";
export const CLEAR_CRASHES = "CLEAR_CRASHES";
//MESSAGE
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
//AUTH
export const LOG_IN = "LOG_IN";
export const REGISTER = "REGISTER";
export const LOG_OUT = "LOG_OUT";


//WATCH-TOWER
export const GET_CONTACT_REQUESTS_WT = "GET_CONTACT_REQUESTS_WT";


//CONSUMER
export const GET_ALL_USERS_CON = "GET_ALL_USERS_CON";
export const GET_INTERNAL_USERS_CON = "GET_INTERNAL_USERS_CON";

//CONSUMER -> PAYMENTS
export const GET_CURRENCIES_CON_PAYMENTS = "GET_CURRENCIES_CON_PAYMENTS";
export const GET_USERS_CON_PAYMENTS = "GET_USERS_CON_PAYMENTS";

//CONSUMER -> VIRTUALCARDS
export const GET_CURRENCIES_CON_VCN = "GET_CURRENCIES_CON_VCN";

 
//BUSINESS
export const GET_UPLOADED_FILES_BIZ = "GET_UPLOADED_FILES_BIZ";
export const GET_CURRENCIES_BIZ = "GET_CURRENCIES_BIZ";

//BUSINESS -> NOTIFICATIONS
export const GET_EMAIL_TEMPLATES_BIZ_NOTIFICATIONS = "GET_EMAIL_TEMPLATES_BIZ_NOTIFICATIONS";
export const GET_EMAIL_TEMPLATE_BIZ_NOTIFICATIONS = "GET_EMAIL_TEMPLATE_BIZ_NOTIFICATIONS";

export const GET_EMAIL_ACTIVITIES_BIZ_NOTIFICATIONS = "GET_EMAIL_ACTIVITIES_BIZ_NOTIFICATIONS";

export const GET_SMS_TEMPLATES_BIZ_NOTIFICATIONS = "GET_SMS_TEMPLATES_BIZ_NOTIFICATIONS";
export const GET_SMS_TEMPLATE_BIZ_NOTIFICATIONS = "GET_SMS_TEMPLATE_BIZ_NOTIFICATIONS";

export const GET_SMS_ACTIVITIES_BIZ_NOTIFICATIONS = "GET_SMS_ACTIVITIES_BIZ_NOTIFICATIONS";

export const GET_OTP_RECORDS_BIZ_NOTIFICATIONS = "GET_OTP_RECORDS_BIZ_NOTIFICATIONS";

//BUSINESS -> NOTIFICATIONS
export const GET_PRODUCTS_BIZ_SUBSCRIPTIONS = "GET_PRODUCTS_BIZ_SUBSCRIPTIONS";
export const GET_DELIVERY_METHODS_BIZ_SUBSCRIPTIONS = "GET_DELIVERY_METHODS_BIZ_SUBSCRIPTIONS";
export const GET_INTEGRATIONS_BIZ_SUBSCRIPTIONS = "GET_INTEGRATIONS_BIZ_SUBSCRIPTIONS";
export const GET_SUBSCRIPTION_PLAN_BIZ_SUBSCRIPTIONS = "GET_SUBSCRIPTION_PLAN_BIZ_SUBSCRIPTIONS";
export const GET_SERVICES_BIZ_SUBSCRIPTIONS = "GET_SERVICES_BIZ_SUBSCRIPTIONS";
export const GET_PLAN_ADDONS_BIZ_SUBSCRIPTIONS = "GET_PLAN_ADDONS_BIZ_SUBSCRIPTIONS";
export const GET_COMPANY_PLAN_ADDONS_BIZ_SUBSCRIPTIONS = "GET_COMPANY_PLAN_ADDONS_BIZ_SUBSCRIPTIONS";
export const GET_DISALLOWED_SERVICES_BIZ_SUBSCRIPTIONS = "GET_DISALLOWED_SERVICES_BIZ_SUBSCRIPTIONS";

//BUSINESS -> IDENTITY
export const GET_COMPANIES_BIZ_IDENTITY = "GET_COMPANIES_BIZ_IDENTITY";
export const GET_BRANCHES_BIZ_IDENTITY = "GET_BRANCHES_BIZ_IDENTITY";
export const GET_USERS_BIZ_IDENTITY = "GET_USERS_BIZ_IDENTITY";
export const GET_COMPANY_EMPLOYEES_BIZ_IDENTITY = "GET_COMPANY_EMPLOYEES_BIZ_IDENTITY";
export const GET_TENANTS_BIZ_IDENTITY = "GET_TENANTS_BIZ_IDENTITY";

//BUSINESS -> CORE
export const SELECT_TENANT_BIZ_CORE = "SELECT_TENANT_BIZ_CORE";
export const CLEAR_TENANT_BIZ_CORE = "CLEAR_TENANT_BIZ_CORE";

export const SELECT_COMPANY_BIZ_CORE = "SELECT_COMPANY_BIZ_CORE";
export const CLEAR_COMPANY_BIZ_CORE = "CLEAR_COMPANY_BIZ_CORE";

export const GET_CURRENCIES_BIZ_CORE = "GET_CURRENCIES_BIZ_CORE";
export const GET_INDUSTRIES_BIZ_CORE = "GET_INDUSTRIES_BIZ_CORE";
export const GET_ACCOUNT_TYPES_BIZ_CORE = "GET_ACCOUNT_TYPES_BIZ_CORE";
export const GET_CHART_OF_ACCOUNTS_BIZ_CORE = "GET_CHART_OF_ACCOUNTS_BIZ_CORE";

export const GET_TENANTS_BIZ_CORE = "GET_TENANTS_BIZ_CORE";
export const GET_COMPANIES_BIZ_CORE = "GET_COMPANIES_BIZ_CORE";

export const GET_BANK_ACCOUNT_TYPES_BIZ_CORE = "GET_BANK_ACCOUNT_TYPES_BIZ_CORE";
export const GET_BANKS_BIZ_CORE = "GET_BANKS_BIZ_CORE";
export const GET_HOLIDAYS_BIZ_CORE = "GET_HOLIDAYS_BIZ_CORE";
export const GET_COST_OBJECTS_BIZ_CORE = "GET_COST_OBJECTS_BIZ_CORE";
export const GET_TENANT_BANK_ACCOUNT_BIZ_CORE = "GET_TENANT_BANK_ACCOUNT_BIZ_CORE";

export const GET_PAYMENT_FREQUENCIES_BIZ_CORE = "GET_PAYMENT_FREQUENCIES_BIZ_CORE";
export const GET_DELIVERY_METHODS_BIZ_CORE = "GET_DELIVERY_METHODS_BIZ_CORE";
export const GET_SERVICE_FEES_BIZ_CORE = "GET_SERVICE_FEES_BIZ_CORE";

export const GET_PAYMENT_METHODS_BIZ_CORE = "GET_PAYMENT_METHODS_BIZ_CORE";
export const GET_CATEGORIES_BIZ_CORE = "GET_CATEGORIES_BIZ_CORE";

export const GET_EMPLOYEES_BIZ_CORE = "GET_EMPLOYEES_BIZ_CORE";
export const GET_COMPANY_BANK_ACCOUNT_BIZ_CORE = "GET_COMPANY_BANK_ACCOUNT_BIZ_CORE";

export const GET_DISCOUNTS_BIZ_CORE = "GET_DISCOUNTS_BIZ_CORE";
export const GET_BILLING_PAYS_BIZ_CORE = "GET_BILLING_PAYS_BIZ_CORE";
export const GET_BILLING_RECEIVES_BIZ_CORE = "GET_BILLING_RECEIVES_BIZ_CORE";
export const GET_BILLING_EXPENSES_BIZ_CORE = "GET_BILLING_EXPENSES_BIZ_CORE";

//BUSINESS -> CORE -> CC
export const GET_COUNTRIES_BIZ_CORE_CC = "GET_COUNTRIES_BIZ_CORE_CC";
export const GET_CURRENCIES_BIZ_CORE_CC = "GET_CURRENCIES_BIZ_CORE_CC";

export const GET_PAYMENT_COUNTRIES_BIZ_CORE_CC = "GET_PAYMENT_COUNTRIES_BIZ_CORE_CC";
export const GET_PAYMENT_CURRENCIES_BIZ_CORE_CC = "GET_PAYMENT_CURRENCIES_BIZ_CORE_CC";
export const GET_PAYMNET_PUPOSES_BIZ_CORE_CC = "GET_PAYMNET_PUPOSES_BIZ_CORE_CC";
export const GET_PAYMENT_COUNTRY_CURRENCY_XREF_BIZ_CORE_CC = "GET_PAYMENT_COUNTRY_CURRENCY_XREF_BIZ_CORE_CC";
export const GET_PAYMENT_SERVICES_BIZ_CORE_CC = "GET_PAYMENT_SERVICES_BIZ_CORE_CC";

export const GET_COLLECTION_COUNTRIES_BIZ_CORE_CC = "GET_COLLECTION_COUNTRIES_BIZ_CORE_CC";
export const GET_COLLECTION_CURRENCIES_BIZ_CORE_CC = "GET_COLLECTION_CURRENCIES_BIZ_CORE_CC";
export const GET_COLLECTION_COUNTRY_CURRENCY_BIZ_CORE_CC = "GET_COLLECTION_COUNTRY_CURRENCY_BIZ_CORE_CC";
export const GET_COLLECTION_SERVICES_BIZ_CORE_CC = "GET_COLLECTION_SERVICES_BIZ_CORE_CC";

//BUSINESS -> FUSION
export const GET_ACTIVITIES_BIZ_FUSION = "GET_ACTIVITIES_BIZ_FUSION";

//BUSINESS -> FUSION -> ORUM
export const GET_WEBHOOKS_BIZ_FUSION_ORUM = "GET_WEBHOOKS_BIZ_FUSION_ORUM";

//BUSINESS -> FUSION -> CC
export const SELECT_ACCOUNT_BIZ_FUSION_CC = "SELECT_ACCOUNT_BIZ_FUSION_CC";
export const CLEAR_ACCOUNT_BIZ_FUSION_CC = "CLEAR_ACCOUNT_BIZ_FUSION_CC";

export const GET_ACCOUNTS_BIZ_FUSION_CC = "GET_ACCOUNTS_BIZ_FUSION_CC";
export const GET_CONTACTS_BIZ_FUSION_CC = "GET_CONTACTS_BIZ_FUSION_CC";
export const GET_BENEFICIARIES_BIZ_FUSION_CC = "GET_BENEFICIARIES_BIZ_FUSION_CC";
export const GET_CONVERSIONS_BIZ_FUSION_CC = "GET_CONVERSIONS_BIZ_FUSION_CC";
export const GET_BASIC_RATES_BIZ_FUSION_CC = "GET_BASIC_RATES_BIZ_FUSION_CC";
export const GET_DETAILED_RATES_BIZ_FUSION_CC = "GET_DETAILED_RATES_BIZ_FUSION_CC";
export const GET_PAYMENTS_BIZ_FUSION_CC = "GET_PAYMENTS_BIZ_FUSION_CC";
export const GET_TRANSFERS_BIZ_FUSION_CC = "GET_TRANSFERS_BIZ_FUSION_CC";

//BUSINESS -> FUSION -> ALTCARD
export const GET_PARTIES_BIZ_FUSION_ALT = "GET_PARTIES_BIZ_FUSION_ALT";
export const GET_PARTIES_BANK_ACCOUNTS_BIZ_FUSION_ALT = "GET_PARTIES_BANK_ACCOUNTS_BIZ_FUSION_ALT";


//BUSINESS -> WEBHOOK
export const GET_ACTIVITIES_BIZ_WEBHOOK = "GET_ACTIVITIES_BIZ_WEBHOOK";
export const GET_WEBHOOK_LOGS_BIZ_WEBHOOK = "GET_WEBHOOK_LOGS_BIZ_WEBHOOK";



//SDK


//SUPPORT
export const GET_ISSUES_SPT = "GET_ISSUES_SPT";


//SUPPORT -> CONFIG
export const GET_CATEGORIES_SPT_CONFIG = "GET_CATEGORIES_SPT_CONFIG";
export const GET_STAGES_SPT_CONFIG = "GET_STAGES_SPT_CONFIG";