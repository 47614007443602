import React from 'react';

import { 
  Box, 
  Typography
} from '@mui/material';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';

import "../styles.scss";

const CoreGlobalCurrencyCloud = ({
  navigate, 
  currentPage
}) => {
  return (
    <Box className="nav__side-common flex__Column" 
      display={"flex"} flexDirection={"column"} gap={"10px"}>
      <Box className="nav__side-container">
        <Box className={`nav__side-item flexCenter_Row`}
          onClick={() => {
            navigate("/business/core/global/currencies");
          }}>
          <ArrowBackIcon />
          <Typography variant='body2'>
            Back
          </Typography>
        </Box>
      </Box>

      <Typography
        mt={"12px"}
        mb={"-5px"}>
        <i>Common</i>
      </Typography>

      <Box className="nav__side-container">
        <Box className={`${currentPage === 0 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/core/global/currency-cloud/countries");
          }}>
          <AttachMoneyOutlinedIcon />
          <Typography variant='body2'>
            Countries
          </Typography>
        </Box>
        <Box className={`${currentPage === 1 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/core/global/currency-cloud/currencies");
          }}>
          <AttachMoneyOutlinedIcon />
          <Typography variant='body2'>
            Currencies
          </Typography>
        </Box>
      </Box>

      <Typography
        mt={"12px"}
        mb={"-5px"}>
        <i>Payment</i>
      </Typography>
      
      <Box className="nav__side-container">
        <Box className={`${currentPage === 2 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/core/global/currency-cloud/payment-countries");
          }}>
          <AttachMoneyOutlinedIcon />
          <Typography variant='body2'>
            Countries
          </Typography>
        </Box>
        <Box className={`${currentPage === 3 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/core/global/currency-cloud/payment-currencies");
          }}>
          <AttachMoneyOutlinedIcon />
          <Typography variant='body2'>
            Currencies
          </Typography>
        </Box>
        <Box className={`${currentPage === 4 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/core/global/currency-cloud/payment-purposes");
          }}>
          <AttachMoneyOutlinedIcon />
          <Typography variant='body2'>
            Purposes
          </Typography>
        </Box>
        <Box className={`${currentPage === 5 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/core/global/currency-cloud/payment-country-currency-xref");
          }}>
          <AttachMoneyOutlinedIcon />
          <Typography variant='body2'>
            Country Currency Xref
          </Typography>
        </Box>
        <Box className={`${currentPage === 6 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/core/global/currency-cloud/payment-services");
          }}>
          <AttachMoneyOutlinedIcon />
          <Typography variant='body2'>
            Services
          </Typography>
        </Box>
      </Box>

      <Typography
        mt={"12px"}
        mb={"-5px"}>
        <i>Collection</i>
      </Typography>

      <Box className="nav__side-container">
        <Box className={`${currentPage === 7 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/core/global/currency-cloud/collection-countries");
          }}>
          <AttachMoneyOutlinedIcon />
          <Typography variant='body2'>
            Countries
          </Typography>
        </Box>
        <Box className={`${currentPage === 8 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/core/global/currency-cloud/collection-currencies");
          }}>
          <AttachMoneyOutlinedIcon />
          <Typography variant='body2'>
            Currencies
          </Typography>
        </Box>
        <Box className={`${currentPage === 9 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/core/global/currency-cloud/collection-country-currency-xref");
          }}>
          <AttachMoneyOutlinedIcon />
          <Typography variant='body2'>
            Country Currency Xref
          </Typography>
        </Box>
        <Box className={`${currentPage === 10 ? 'nav__side-selected' : 'nav__side-item'} flexCenter_Row`}
          onClick={() => {
            navigate("/business/core/global/currency-cloud/collection-services");
          }}>
          <AttachMoneyOutlinedIcon />
          <Typography variant='body2'>
            Services
          </Typography>
        </Box>
      </Box>
      
    </Box>
  )
}

export default CoreGlobalCurrencyCloud;