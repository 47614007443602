import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Box, 
  CircularProgress,  
  IconButton,  
  Menu,  
  MenuItem,  
  Table, 
  TableBody, 
  TableCell, 
  TableFooter, 
  TableHead, 
  TablePagination, 
  TableRow, 
  TableSortLabel, 
  Typography 
} from '@mui/material';

import TablePaginationActions from '../../../../../../components/utils/TablePaginationActions';
import useTablePaginationInit from '../../../../../../components/hooks/useTablePaginationInit';
import TableTimeStamps from '../../../../../../components/utils/TableTimeStamps';
import TopBar from '../../../../../../components/utils/TopBar';
import NA from '../../../../../../components/utils/NA';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import { 
  GetPaymentServicesBIZcoreCC
} from '../../../../../../redux/actions/business/core/currencyCloudActions';

import PaymentServicesModal from './PaymentServicesModal';

import global from "../../../../../../global.scss";

const PaymentServicesPage = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state["business.core.cc"]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [paymentServicesModalOpen, setPaymentServicesModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [update, setUpdate] = useState(null);

  const [paymentServicesList, setPaymentServicesList] = useState([]);
  const [paymentServicesTotal, setPaymentServicesTotal] = useState(0);

  const [paymentServicesMenuOpen, setPaymentServicesMenuOpen] = useState(false);
  const [paymentServicesMenuAnchor, setPaymentServicesMenuAnchor] = useState(null);

  const [
    page, 
    rowsPerPage, 
    handleChangePage, 
    handleChangeRowsPerPage
  ] = useTablePaginationInit();

//   {
//     "id": "e36767c1-2c62-49eb-a308-c272649d248e",
//     "payment_type": "REGULAR",
//     "cutoff_time": "2025-01-18T11:20:03.297000Z",
//     "min_limit": -1,
//     "max_limit": 1500000,
//     "delivery_time_frame": 0,
//     "is_active": false,
//     "created_at": "2025-01-18T11:20:05.995489Z",
//     "updated_at": "2025-01-18T11:20:05.995508Z",
//     "ref_pmt_country_currency_id": "9698c352-927f-4a36-ad08-e743314a3800"
// }

  const tableHeadingsList = [
    {
      label: "Id",
      sort: "id",
    },
    {
      label: "Payment Country Currency",
      sort: "ref_pmt_country_currency_id",
    },
    {
      label: "Payment type",
      sort: "payment_type",
    },
    {
      label: "Cutoff time",
      sort: "cutoff_time",
    },
    {
      label: "Limits",
      sort: "max_limit",
    },
    {
      label: "Delivery time frame",
      sort: "delivery_time_frame",
    },
    {
      label: "Active",
      sort: "is_active",
    },
    {
      label: "Time stamps",
      sort: "created_at",
    },
    {
      label: "",
      sort: null
    }
  ];

  const handleSort = (value) => {
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    setSort(value);
  };

  useEffect(() => {
    handleGetPaymentServices();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, search, sort, sortDirection]);

  useEffect(() => {
    try {
      formatPaymentServicesList(state.paymentServicesList.records || []);
      setPaymentServicesTotal(state.paymentServicesList.total || 0);
    } catch (err) {}
  }, [state]);

  const formatPaymentServicesList = (list) => {
    setPaymentServicesList(list)
  };

  async function handleGetPaymentServices(customLoading){
    let query = ""
    if(search) query += `&search=${search}`;
    if(sort) query += `&ordering=${sortDirection === "asc" ? '' : '-'}${sort}`;
    await dispatch(GetPaymentServicesBIZcoreCC(page+1, rowsPerPage, query, customLoading ? customLoading : setLoading));
  };

  return (
    <Box>

      <PaymentServicesModal 
        open={paymentServicesModalOpen}
        setOpen={setPaymentServicesModalOpen}
        title={modalTitle}
        update={update}
        handleGet={handleGetPaymentServices}
        />

      <TopBar
        title={"Payment Services"}
        search={search}
        onSearchChange={(e) => {
          setSearch(e.target.value);
        }}
        button1Text={"Add Payment Service"}
        button1OnClick={() => {
          setUpdate({
            defaultMode: 1
          });
          setModalTitle("Add Payment Service");
          setPaymentServicesModalOpen(!paymentServicesModalOpen);
        }}
        />

      {
        loading
        ?
        <Box height={"300px"} className="flexCenterCenterRow">
          <CircularProgress />
        </Box>
        :
          paymentServicesList.length === 0
          ?
          <Box height={"300px"} className="flexCenterCenterRow">
            <Typography>No payment service found</Typography>
          </Box>
          :
          <Table className="block">
            <TableHead>
              <TableRow>
                {
                  tableHeadingsList.map((heading, headingIndex) => (
                    heading.sort === null
                    ?
                    <TableCell
                      key={headingIndex}>{heading.label}</TableCell>
                    :
                    <TableCell
                      key={headingIndex}>
                      <TableSortLabel
                        active={sort === heading.sort ? true : false}
                        direction={sortDirection}
                        onClick={() => {
                          handleSort(heading.sort);
                        }}
                      >
                        {heading.label}
                      </TableSortLabel>
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                paymentServicesList.map((row, index) => (
                  <TableRow 
                    key={index}>
                    <TableCell>
                      <Typography
                        variant='caption'
                        color={"grey"}>
                        {row.id || <NA />}
                      </Typography>
                    </TableCell>                    
                    <TableCell>{row.ref_pmt_country_currency_id || <NA />}</TableCell>
                    <TableCell>{row.payment_type || <NA />}</TableCell>
                    <TableCell>{row.cutoff_time || <NA />}</TableCell>
                    <TableCell>{row.min_limit || <NA />} - {row.max_limit || <NA />}</TableCell>
                    <TableCell>{row.delivery_time_frame}</TableCell>
                    <TableCell>{row.is_active ? "Yes" : "No"}</TableCell>
                    <TableCell>
                      <TableTimeStamps
                        createdAt={row.created_at}
                        updatedAt={row.updated_at}
                        />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={(e) => {
                          setPaymentServicesMenuAnchor(e.currentTarget);
                          setPaymentServicesMenuOpen(true);
                          setUpdate(row);
                          setModalTitle(row.name || "");
                        }}>
                        <MoreVertIcon sx={{ color: global['secondary_light']}}/>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  sx={{ borderBottom: 0 }}
                  rowsPerPageOptions={[5, 10, 25, 50, 100]} 
                  colSpan={10}
                  count={paymentServicesTotal}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page'
                    },
                    native: true
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  />
              </TableRow>
            </TableFooter>
          </Table>
      }
      <Menu
        open={paymentServicesMenuOpen}
        anchorEl={paymentServicesMenuAnchor}
        onClose={() => setPaymentServicesMenuOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            setModalTitle("Edit " + modalTitle);
            setUpdate({
              ...update,
              defaultMode: 2
            });
            setPaymentServicesMenuOpen(false);
            setPaymentServicesModalOpen(!paymentServicesModalOpen);
          }}>
          <Box
            className="flexCenter_Row"
            gap={"10px"}>
            <EditOutlinedIcon />
            Edit
          </Box>
        </MenuItem>
      </Menu>
    </Box>
  )
}

export default PaymentServicesPage;