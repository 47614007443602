import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { 
  Avatar,
  Box, 
  IconButton, 
  Menu, 
  MenuItem,
  Tooltip,
  Typography, 
} from '@mui/material';

import Logo from "../../assets/icons/logo-white.svg";
import NA from '../utils/NA';
import LogoutIcon from '@mui/icons-material/Logout';
import AppsRoundedIcon from '@mui/icons-material/AppsRounded';

import ConfirmationModal from '../modals/ConfirmationModal';

import WatchTowerNavigation from './WatchTowerNavigation';
import ConsumerNavigation from './ConsumerNavigation';
import BusinessNavigation from './BusinessNavigation/index';
import SDKNavigation from './SDKNavigation';
import SupportNavigation from './SupportNavigation';

import {
  moduleList
} from "../../views/homePage/HomePage";

import * as actionTypes from "../../redux/actionTypes";

import "./styles.scss";

const Navigation = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [currentModule, setCurrentModule] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const businessCoreState = useSelector(state => state["business.core"]);
  const businessFusionCCState = useSelector(state => state["business.fusion.cc"]);

  const [profileAnchor, setProfileAnchor] = useState(null);
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const handleCloseProfileMenu = () => {
    setProfileAnchor(null);
    setProfileMenuOpen(false);
  };

  useEffect(() => {
    var route = location.pathname.split("/");
    var module = route[1];
    var page = route.splice(2).join("/");

    switch(module){      
      case "watch":
        setCurrentModule(0);
        switch(page){
          case "": return setCurrentPage(0);
          case "contact-requests": return setCurrentPage(1);
          case "app-versions": return setCurrentPage(2);
          // submodule -> environment
          case "environment": return setCurrentPage(0);
          case "environment/consumer": return setCurrentPage(1);
          case "environment/business": return setCurrentPage(2);
          case "environment/sdk": return setCurrentPage(3);
          case "environment/support": return setCurrentPage(4);

          default: return setCurrentPage(0);
        }

      case "consumer":
        setCurrentModule(1);
        switch(page){
          case "": return setCurrentPage(0);
          case "users": return setCurrentPage(1);
          case "internal-users": return setCurrentPage(2);
          // submodule -> extension
          case "extension": return setCurrentPage(0);
          case "extension/card": return setCurrentPage(1);
          case "extension/price": return setCurrentPage(2);
          // submodule -> payments
          case "payments/currencies": return setCurrentPage(0);
          case "payments/users": return setCurrentPage(1);
          // submodule -> virtualCards
          case "virtual-cards/currencies": return setCurrentPage(0);
  
          default: return setCurrentPage(0);
        }

      case "business":
        setCurrentModule(2);
        switch(page){
          case "": return setCurrentPage(0);
          case "uploaded-files": return setCurrentPage(1);
          case "currencies": return setCurrentPage(2);
          // submodule -> extensions
          case "extension": return setCurrentPage(0);
          // submodule -> notifications
          case "notifications/email-templates": return setCurrentPage(0); 
          case "notifications/email-activities": return setCurrentPage(1);
          case "notifications/sms-templates": return setCurrentPage(2); 
          case "notifications/sms-activities": return setCurrentPage(3);
          case "notifications/otp-records": return setCurrentPage(4); 
          // submodule -> subscriptions
          case "subscriptions": return setCurrentPage(0);
          case "subscriptions/products": return setCurrentPage(1);
          case "subscriptions/delivery-methods": return setCurrentPage(2);
          case "subscriptions/integrations": return setCurrentPage(3);
          case "subscriptions/subscription-plans": return setCurrentPage(4);
          case "subscriptions/services": return setCurrentPage(5);
          case "subscriptions/plan-addons": return setCurrentPage(6);
          case "subscriptions/company-plan-addons": return setCurrentPage(7);
          case "subscriptions/disallowed-services": return setCurrentPage(8);
          // submodule -> identity
          case "identity": return setCurrentPage(0); 
          case "identity/tenants": return setCurrentPage(1); 
          case "identity/companies": return setCurrentPage(2);
          case "identity/branches": return setCurrentPage(3); 
          case "identity/users": return setCurrentPage(4);
          case "identity/company-employees": return setCurrentPage(5);
          // submodule -> core
          case "core": return setCurrentPage(0); 
          case "core/global/currencies": return setCurrentPage(0); 
          case "core/global/industries": return setCurrentPage(1); 
          case "core/global/account-types": return setCurrentPage(2); 
          case "core/global/chart-of-accounts": return setCurrentPage(3); 
          case "core/global/currency-cloud/countries": return setCurrentPage(0); 
          case "core/global/currency-cloud/currencies": return setCurrentPage(1); 
          case "core/global/currency-cloud/payment-countries": return setCurrentPage(2); 
          case "core/global/currency-cloud/payment-currencies": return setCurrentPage(3); 
          case "core/global/currency-cloud/payment-purposes": return setCurrentPage(4); 
          case "core/global/currency-cloud/payment-country-currency-xref": return setCurrentPage(5); 
          case "core/global/currency-cloud/payment-services": return setCurrentPage(6); 
          case "core/global/currency-cloud/collection-countries": return setCurrentPage(7); 
          case "core/global/currency-cloud/collection-currencies": return setCurrentPage(8); 
          case "core/global/currency-cloud/collection-country-currency-xref": return setCurrentPage(9); 
          case "core/global/currency-cloud/collection-services": return setCurrentPage(10); 
          case "core/tenants": return setCurrentPage(0); 
          case "core/tenants/bank-account-types": return setCurrentPage(0); 
          case "core/tenants/banks": return setCurrentPage(1); 
          case "core/tenants/holidays": return setCurrentPage(2); 
          case "core/tenants/cost-objects": return setCurrentPage(3); 
          case "core/tenants/tenant-bank-accounts": return setCurrentPage(4);
          case "core/tenants/service-fees": return setCurrentPage(5); 
          case "core/tenants/payment-frequencies": return setCurrentPage(6); 
          case "core/tenants/payment-methods": return setCurrentPage(7);
          case "core/tenants/categories": return setCurrentPage(8); 
          case "core/companies": return setCurrentPage(0); 
          case "core/companies/employees": return setCurrentPage(0); 
          case "core/companies/bank-accounts": return setCurrentPage(1); 
          case "core/companies/discounts": return setCurrentPage(2); 
          case "core/companies/billing-pay": return setCurrentPage(3); 
          case "core/companies/billing-receive": return setCurrentPage(4); 
          case "core/companies/billing-expense": return setCurrentPage(5); 
          case "core/reports/domestic-transfers": return setCurrentPage(0); 
          case "core/reports/international-transfers": return setCurrentPage(1); 
          // submodule -> fusion
          case "fusion": return setCurrentPage(0); 
          case "fusion/activities": return setCurrentPage(1); 
          case "fusion/orum": return setCurrentPage(0); 
          case "fusion/orum/businesses": return setCurrentPage(1); 
          case "fusion/orum/persons": return setCurrentPage(2); 
          case "fusion/orum/transfers": return setCurrentPage(3); 
          case "fusion/orum/verify": return setCurrentPage(4); 
          case "fusion/orum/webhooks": return setCurrentPage(5); 
          case "fusion/currency-cloud": return setCurrentPage(0); 
          // case "fusion/currency-cloud/accounts": return setCurrentPage(1); 
          case "fusion/currency-cloud/beneficiaries": return setCurrentPage(0); 
          case "fusion/currency-cloud/conversions": return setCurrentPage(1); 
          case "fusion/currency-cloud/rates": return setCurrentPage(2); 
          case "fusion/currency-cloud/payments": return setCurrentPage(3); 
          case "fusion/currency-cloud/transfers": return setCurrentPage(4); 
          case "fusion/altcard": return setCurrentPage(0); 
          case "fusion/altcard/parties": return setCurrentPage(1); 
          case "fusion/altcard/parties-bank-accounts": return setCurrentPage(2); 
          // submodule -> webhook
          case "webhook": return setCurrentPage(0); 
          case "webhook/activities": return setCurrentPage(1); 
          case "webhook/webhook-logs": return setCurrentPage(2); 
          
    
          default: return setCurrentPage(0);
        }

      case "sdk":
        setCurrentModule(3);
        switch(page){
          case "": return setCurrentPage(0);
    
          default: return setCurrentPage(0);
        }

      case "support":
        setCurrentModule(4);
        switch(page){
          case "": return setCurrentPage(0);
          case "issues": return setCurrentPage(1);
          // submodule -> config
          case "config/categories": return setCurrentPage(0);
          case "config/stages": return setCurrentPage(1);
    
          default: return setCurrentPage(0);
        }

      default: return setCurrentModule(-1);
    }

  }, [currentPage, location.pathname]);

  // ======================= Log out handler ============================
  const handleLogout = () => {
    dispatch({ type: actionTypes.LOG_OUT });
    navigate("/");
  }

  // ======================= Navigate handler ===========================
  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <Box className="nav">
      <ConfirmationModal 
        open={confirmationModalOpen}
        setOpen={setConfirmationModalOpen}
        title={"Log out"}
        onConfirm={handleLogout}
        />

      <Box className="nav__topbar flexCenterSBRow">
        <Box
          className="flexCenter_Row"
          gap={"40px"}>
          <img
            src={Logo}
            alt="logo"
            style={{ width: "140px", cursor: "pointer" }}
            onClick={() => {
              navigate("/home")
            }}
            />
          {
            currentModule >= 0
            &&
            <Typography
              variant='h6'>
              <i>{moduleList[currentModule].name}</i>
            </Typography>
          }
        </Box>

        {
          location.pathname.split("/").length === 5 &&
          location.pathname.split("/")[3] === "tenants" && 
          businessCoreState.selectedTenant !== null
          &&
          <Box
            className="flexCenter_Row">
            <Typography
              color={"grey"}>
              Selected Tenant:
            </Typography>&nbsp;
            <Typography>
              {businessCoreState?.selectedTenant?.tenant_name || <NA />}
            </Typography>
          </Box>
        }

        {
          location.pathname.split("/").length === 5 &&
          location.pathname.split("/")[3] === "companies" && 
          businessCoreState.selectedCompany !== null
          &&
          <Box
            className="flexCenter_Row">
            <Typography
              color={"grey"}>
              Selected Company:
            </Typography>&nbsp;
            <Typography>
              {businessCoreState?.selectedCompany?.company_name || <NA />}
            </Typography>
          </Box>
        }

        {
          location.pathname.split("/").length === 5 &&
          location.pathname.split("/")[3] === "currency-cloud" && 
          businessFusionCCState.selectedAccount !== null
          &&
          <Box
            className="flexCenter_Row">
            <Typography
              color={"grey"}>
              On behalf of:
            </Typography>&nbsp;
            <Typography>
              {businessFusionCCState?.selectedAccount?.accountName || <NA />}
            </Typography>
          </Box>
        }
        
        <Box
          className="flexCenter_Row"
          gap={"15px"}>
          <Tooltip
            title="Change module">
            <IconButton
              onClick={() => {
                navigate("/home")
              }}>
              <AppsRoundedIcon 
                fontSize='large'
                sx={{ color: "grey" }}
                />
            </IconButton>
          </Tooltip>
          <IconButton
            onClick={(event) => {
              setProfileAnchor(event.currentTarget);
              setProfileMenuOpen(!profileMenuOpen);
            }}>
            <Avatar>
              L
            </Avatar>
          </IconButton>
        </Box>
        <Menu
          id="basic-menu"
          anchorEl={profileAnchor}
          open={profileMenuOpen}
          onClose={handleCloseProfileMenu}
        >
          <MenuItem
            className='flexCenterSBRow'
            sx={{ gap: "15px" }}
            onClick={() => {
              setConfirmationModalOpen(true);
              handleCloseProfileMenu();
            }}>
            <LogoutIcon />  
            Logout
          </MenuItem>
        </Menu>
      </Box>
      <Box 
        display={"flex"} 
        flexDirection={"row"}>
        <Box
          overflow={"auto"}
          minWidth={"300px"}
          height={"calc(100vh - 72px)"}>
          {
            currentModule === 0
            ?
            <WatchTowerNavigation 
              path={location.pathname.split("/")}
              currentPage={currentPage}
              navigate={handleNavigate}/>
            :
              currentModule === 1
              ?
              <ConsumerNavigation
                path={location.pathname.split("/")}
                currentPage={currentPage}
                navigate={handleNavigate}/>
              : 
                currentModule === 2
                ?
                <BusinessNavigation 
                  path={location.pathname.split("/")}
                  currentPage={currentPage}
                  navigate={handleNavigate}/>
                :
                  currentModule === 3
                  ?
                  <SDKNavigation
                    path={location.pathname.split("/")}
                    currentPage={currentPage}
                    navigate={handleNavigate}/>
                  :
                    currentModule === 4
                    ?
                    <SupportNavigation 
                      path={location.pathname.split("/")}
                      currentPage={currentPage}
                      navigate={handleNavigate}/>
                    :
                    <></>
          }
        </Box>
        <Box 
          padding={"15px 15px 15px 15px"} 
          width={"100%"}
          height={"calc(100vh - 74px)"}
          overflow={"auto"}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  )
}

export default Navigation;