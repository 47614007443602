import * as actionTypes from "../actionTypes.js";

const initialState = {
  // common
  countriesList: null, 
  currenciesList: null, 

  // payment
  paymentCountriesList: null, 
  paymentCurrenciesList: null, 
  paymentPurposesList: null, 
  paymentCountryCurrencyXrefList: null, 
  paymentServicesList: null, 

  // collection
  collectionCountriesList: null, 
  collectionCurrenciesList: null, 
  collectionCountryCurrencyXrefList: null, 
  collectionServicesList: null, 
};

const businessCoreCCReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_COUNTRIES_BIZ_CORE_CC:
      return { ...state, countriesList: action.payload };
    case actionTypes.GET_CURRENCIES_BIZ_CORE_CC:
      return { ...state, currenciesList: action.payload };

    case actionTypes.GET_PAYMENT_COUNTRIES_BIZ_CORE_CC:
      return { ...state, paymentCountriesList: action.payload };
    case actionTypes.GET_PAYMENT_CURRENCIES_BIZ_CORE_CC:
      return { ...state, paymentCurrenciesList: action.payload };
    case actionTypes.GET_PAYMNET_PUPOSES_BIZ_CORE_CC:
      return { ...state, paymentPurposesList: action.payload };
    case actionTypes.GET_PAYMENT_COUNTRY_CURRENCY_XREF_BIZ_CORE_CC:
      return { ...state, paymentCountryCurrencyXrefList: action.payload };
    case actionTypes.GET_PAYMENT_SERVICES_BIZ_CORE_CC:
      return { ...state, paymentServicesList: action.payload };

    case actionTypes.GET_COLLECTION_COUNTRIES_BIZ_CORE_CC:
      return { ...state, collectionCountriesList: action.payload };
    case actionTypes.GET_COLLECTION_CURRENCIES_BIZ_CORE_CC:
      return { ...state, collectionCurrenciesList: action.payload };
    case actionTypes.GET_COLLECTION_COUNTRY_CURRENCY_BIZ_CORE_CC:
      return { ...state, collectionCountryCurrencyXrefList: action.payload };
    case actionTypes.GET_COLLECTION_SERVICES_BIZ_CORE_CC:
      return { ...state, collectionServicesList: action.payload };


    case actionTypes.LOG_OUT:
      return initialState;

    default:
      return state;
  }
};

export default businessCoreCCReducer;