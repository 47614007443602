import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import {
  Box,
  Button,
  Divider,
  MenuItem,
  Modal, 
  Switch, 
  TextField, 
  Typography
} from '@mui/material';

import LoadingButton from '../../../../components/buttons/Button';

import { 
  CreateDeliveryMethodBIZsubs,  
  UpdateDeliveryMethodBIZsubs 
} from '../../../../redux/actions/business/subscriptions/deliveryMethodsActions';

import global from "../../../../global.scss";

const Data = {
  DeliveryMethod: "",
  DeliverBy: "",
  DeliverySpeed: 0,
  IsDisallowedAtRegion: false
};

const deliveryMethodsList = [
  {
    label: "Standard ACH",
    value : "STANDARD_ACH"
  },
  {
    label: "Same day ACH",
    value : "SAME_DAY_ACH"
  },
  {
    label: "Next day ACH",
    value : "NEXT_DAY_ACH"
  },
  {
    label: "Instant Transfer",
    value : "INSTANT_TRANSFER"
  },
  {
    label: "Holiday Delivery",
    value : "HOLIDAY_DELIVERY"
  },
  {
    label: "Domestic Wire",
    value : "DOMESTIC_WIRE"
  },
  {
    label: "Card",
    value : "CARD"
  }
];

const deliveredByList = [
  {
    label: "A2A",
    value : "A2A"
  },
  {
    label: "Email",
    value : "EMAIl"
  },
  {
    label: "Payment Gateway",
    value : "PAYMENT_GATEWAY"
  },
];

const DeliveryMethodsModal = ({
  open, 
  setOpen, 
  title,
  update,
  handleGet
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(Data);
  const [mode, setMode] = useState(1);

  const handleClose = () => {
    setOpen(false);
    setFormData(Data);
    setMode(1);
  }

  useEffect(() => {
    try {
      if(open){
        if(update.defaultMode === 1){
          // mode = 1 - create delivery method
          setMode(1);
        }else if(update.defaultMode === 2){
          // mode = 2 - edit delivery method
          setMode(2);
          setFormData({
            ...formData,
            DeliveryMethod: update.delivery_method || "",
            DeliverBy:  update.deliver_by || "",
            DeliverySpeed: update.delivery_speed || 0,
            IsDisallowedAtRegion: update.is_disallowed_at_region || false,
          })
        }else{
          toast.error("Invalid modal");
          handleClose();
        }
      }
    } catch (err) {
      console.log(err);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  // --------------- handle change ----------------
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const handleSubmit = () => {
    if(mode === 1){
      // create delivery method
      let obj = {
        delivery_method: formData.DeliveryMethod || "",
        deliver_by: formData.DeliverBy || "",
        delivery_speed: formData.DeliverySpeed || 0,
        is_disallowed_at_region: formData.IsDisallowedAtRegion || false,
      };
      dispatch(CreateDeliveryMethodBIZsubs(obj, setLoading)).then(() => {
        handleGet().then(() => {
          handleClose();
        })
      });
    }else if(mode === 2){
      // update delivery method
      let obj = {
        delivery_method: formData.DeliveryMethod || "",
        deliver_by: formData.DeliverBy || "",
        delivery_speed: formData.DeliverySpeed || 0,
        is_disallowed_at_region: formData.IsDisallowedAtRegion || false,
      };
      dispatch(UpdateDeliveryMethodBIZsubs(update.id, obj, setLoading)).then(() => {
        handleGet().then(() => {
          handleClose();
        })
      });
    }else{
      toast.error("Invalid modal");
      handleClose();
    }
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className="flexCenterCenterRow"
        width={"100vw"} height={"100vh"}>
        <Box maxWidth={"sm"} className="modal">
          <Box width={"100%"}
            sx={{ padding: "15px"}}>
            <Typography variant='h6' sx={{ color: global["primary"] }} mb={"20px"}>
              {title}
            </Typography>
            <Box 
              className="flexCenterSBRow"
              gap={"15px"}
              mb={"15px"}>
              <TextField 
                select
                label="Delivery method"
                fullWidth
                name="DeliveryMethod"
                value={formData.DeliveryMethod}
                onChange={handleChange}
                inputProps={{ maxLength: 255 }}>
                {
                  deliveryMethodsList.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))
                }
              </TextField>
              <TextField 
                select
                label="Deliver by"
                fullWidth
                name="DeliverBy"
                value={formData.DeliverBy}
                onChange={handleChange}
                inputProps={{ maxLength: 255 }}>
                {
                  deliveredByList.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))
                }
              </TextField>
            </Box>
            <Box 
              className="flexCenterSBRow"
              gap={"15px"}
              mb={"15px"}>
              <TextField 
                label="Delivery speed"
                sx={{ maxWidth: "50%" }}
                name="DeliverySpeed"
                type='number'
                value={formData.DeliverySpeed}
                onChange={handleChange}
                inputProps={{ maxLength: 1 }}
                />
              <Box
                className="flexCenterFERow"
                gap={"15px"}>
                <Typography>
                  Is disallowed at region
                </Typography>
                <Switch
                  checked={formData.IsDisallowedAtRegion}
                  onChange={(e) => {
                    handleChange({ target: { name: "IsDisallowedAtRegion", value: e.target.checked }})
                  }}
                  />
                
              </Box>
            </Box>
          </Box>
          <Divider
           sx={{ backgroundColor: "rgba(44, 45, 47, 1)" }}/>
          <Box className="flexCenterFERow" sx={{ gap: "20px", margin: "15px"}}>
            <Button variant='text'
              onClick={handleClose}>
              Cancel
            </Button>
            <LoadingButton 
              variant='contained'
              loading={loading}
              disabled={!(formData.DeliveryMethod !== "" && formData.DeliverBy !== "")}
              onClick={handleSubmit}>
              Submit
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default DeliveryMethodsModal;